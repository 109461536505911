import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Licrej1 } from '../../../components/images/support/certifications/Licrej1.generated';
import { Licrej2 } from '../../../components/images/support/certifications/Licrej2.generated';
import { Licrej3 } from '../../../components/images/support/certifications/Licrej3.generated';
import { Licrej4 } from '../../../components/images/support/certifications/Licrej4.generated';
import { Licrej5 } from '../../../components/images/support/certifications/Licrej5.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "requesting-certification-updates-from-an-employee"
    }}>{`Requesting Certification Updates from an Employee`}</h1>
    <p>{`Sometimes we want an employee to upload a new version of a certification. Common reasons for this
include:`}</p>
    <ol>
      <li parentName="ol">{`The current version of the certification has expired`}</li>
      <li parentName="ol">{`We want a more up to date version of a certification`}</li>
    </ol>
    <p>{`This page demonstrates how to reject a certification so that the employee receives a
notification to upload the certification again.`}</p>
    <p>{`Lets get started 🚀`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the users page and click into the respective user.`}</li>
    </ol>

    <Licrej1 mdxType="Licrej1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`View`}</strong>{` for the certification you want the employee to update`}</li>
    </ol>

    <Licrej2 mdxType="Licrej2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Reject`}</strong>{` button`}</li>
    </ol>

    <Licrej3 mdxType="Licrej3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Enter the reason you need the certification updated and click `}<strong parentName="li">{`Reject and send message`}</strong></li>
    </ol>

    <Licrej4 mdxType="Licrej4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`You can now see the status of the certification is `}<strong parentName="li">{`Rejected`}</strong>{`. Once the employee uploads the
new certification the status will change to `}<strong parentName="li">{`Submitted`}</strong></li>
    </ol>

    <Licrej5 mdxType="Licrej5" />
    <h1 {...{
      "id": "great-work-you-have-requested-an-update-"
    }}>{`Great work you have requested an update 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      